<template>
    <div class="card mb-5 mb-xl-10 p-2">
        <div class="card-header border-1 cursor-pointer">
            <div class="card-title m-0">
                <h3 class="fw-bolder m-0">{{ $t("Company Details") }}</h3>
            </div>
        </div>
        <CompanyDetailsForm
            :businessData="businessData"
            :industries="industries"
            :employeesNumbers="employeesNumbers"
            :globalRevenues="globalRevenues"
        />
    </div>

    <div class="card mb-5 mb-xl-10">
        <div class="card-header border-0 cursor-pointer">
            <div class="card-title m-0">
                <h3 class="fw-boldest m-0">{{ $t("Close Company Account") }}</h3>
            </div>
        </div>
        <DeactivateAccount
            :type="'business'"
        />
    </div>
</template>
<script>
import CompanyDetailsForm from "@/buying-teams/shared-components/settings/CompanyDetailsForm";
import DeactivateAccount from "@/buying-teams/shared-components/settings/DeactivateAccount";
import SignInMethod from "@/buying-teams/shared-components/settings/SignInMethod";
import store from "@/store";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "Settings",

    components: {
        CompanyDetailsForm,
        DeactivateAccount,
        SignInMethod
    },

    data() {
        return {
            isActiveSecureTab: true
        };
    },

    async mounted() {
        if (!this.currentUser.can(BusinessUserPermissionTypeEnum.EDIT_BUSINESS)) {
            this.$router.push('/business/settings/business-settings/overview');
        }
        await store.dispatch("fetchStaticData", StaticDataEnum.INDUSTRIES);
        await store.dispatch("fetchStaticData", StaticDataEnum.EMPLOYEE_COUNTS);
        await store.dispatch("fetchStaticData", StaticDataEnum.REVENUES);
    },

    computed: {
        businessData() {
            return store.getters.business;
        },
        industries() {
            return store.getters.allIndustries;
        },
        employeesNumbers() {
            return store.getters.employeesCounts;
        },
        globalRevenues() {
            return store.getters.globalRevenues;
        },
        currentUser() {
            return store.getters.currentUser;
        },
    }

};
</script>
<style lang="scss" scoped>

</style>
