<template>
  <div id="kt_account_profile_details" class="collapse show companyDetailsForm">
    <el-form class="w-xl-75"
             v-if="businessData"
             ref="businessFormRef"
             :model="businessForm"
             :rules="businessFormRules"
             label-width="300px">



      <el-form-item class="fullName" :label="$t('Logo')">
        <FileUploadWithCropper
          :imageUrl="businessForm.icon_path === Constants.BUSINESS_TMP_LOGO ? null : businessForm.icon_path"
          :loading="fileUploadLoading"
          :allowedFileTypes="['png', 'jpg', 'jpeg', 'JPG']"
          :acceptedSize="9216"
          :blank-image-type="BlankImageTypesEnum.COMPANY"
          :popup-title="$t('Crop Image')"
          :popup-description="$t('Confirm your image upload by properly adjusting your Logo')"
          @getImages="handleUpload"
          @getErrors="errors => {fileUploadErrors = errors}"
        />
        <div class="el-form-item__error">{{fileUploadErrors.join(', ')}}</div>
      </el-form-item>

      <el-form-item prop="company_name" :label="$t('Company Name')">
        <el-input
          v-model="businessForm.company_name"
          :placeholder="$t('Input your Company Name')" />
      </el-form-item>

      <el-form-item prop="country" :label="$t('Global Headquarters')">
        <el-select
          v-model="businessForm.country"
          filterable
          :placeholder="$t('Enter your Country Headquarter')">
          <el-option
            v-for="(item,index) in countries"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="industries" :label="$t('Industry')">
        <el-select
          multiple
          collapse-tags
          v-model="businessForm.industries"
          :placeholder="$t('Select your Industry')"
        >
          <el-option
            v-for="(industry, index) in industries"
            :key="index"
            :label="industry"
            :value="industry"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="num_employees" class="mt-15" :label="$t('Global Number of Employees')">
        <el-select
          v-model="businessForm.num_employees"
          :placeholder="$t('Select relevant range')">
          <el-option
            v-for="(employeesNumber, index) in employeesNumbers"
            :key="index"
            :label="employeesNumber"
            :value="employeesNumber"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="revenue" :label="$t('Global Revenue')">
        <el-select
          v-model="businessForm.revenue"
          :placeholder="$t('Select revenue range')">
          <el-option
            v-for="(globalRevenue, index) in globalRevenues"
            :key="index"
            :label="globalRevenue.label"
            :value="globalRevenue.value"
          ></el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <hr />
    <div class="d-flex mt-10 justify-content-end align-items-center">
      <el-button @click="resetForm">{{ $t("Discard") }}</el-button>

      <button
        :data-kt-indicator="buttonIndicator"
        :disabled="submitButtonState"
        class="main-btn btn"
        @click="onSubmit()">
        <span class="indicator-label"> {{ $t("Save Changes") }}</span>

        <span class="indicator-progress">
        {{ $t("pleaseWait") }}
        <span
          class="spinner-border spinner-border-sm align-middle ms-2"
        ></span>
      </span>

      </button>
    </div>
  </div>
</template>
<script>

  import { BusinessModel } from "@/store/models/BusinessModel";
  import store from "../../../store";
  import NotificationService from "../../services/NotificationService";
  import { DataService } from "@/core/services/DataService";
  import FileUploadWithCropper from "@/buying-teams/shared-components/utils/FileUploadWithCropper";
  import { Constants } from "@/core/config/constants";
  import {BlankImageTypesEnum} from "@/store/enums/BlankImageTypesEnum";

  export default {
    name: "CompanyDetailsForm",

    props: {
      businessData: BusinessModel,
      industries: Array,
      employeesNumbers: Array,
      globalRevenues: Array
    },

    components: {
      FileUploadWithCropper,
    },

    data() {
      return {
        BlankImageTypesEnum,
        Constants,
        buttonIndicator: "off",
        fileUploadErrors: [],
        fileUploadLoading: false,
        businessForm: {
          company_name: "",
          country: "",
          industries: [],
          num_employees: "",
          revenue: ""
        },
        initialBusinessFormJSON: null,
        businessFormRules: {
          company_name: [
            { required: true, message: this.$t("Please input Group Company Name"), trigger: "change" },
            { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
          ],
          industries: [
            { required: true, message: this.$t("Please select Industry"), trigger: "change" }
          ],
          num_employees: [
            { required: true, message: this.$t("Please select Number of Employees"), trigger: "change" }
          ],
          revenue: [
            { required: true, message: this.$t("Please select Global revenue"), trigger: "change" }
          ]
        }
      };
    },

    computed: {
      countries() {
        return store.getters.allCountries;
      },
      submitButtonState() {
        return this.buttonIndicator === 'on' || this.fileUploadLoading || JSON.stringify(this.businessForm) === this.initialBusinessFormJSON;
      }
    },

    async mounted() {
      if (this.businessData) {
        this.fillForm({ ...this.businessData });
        this.initialBusinessFormJSON = JSON.stringify(this.businessForm);
      }
    },

    methods: {
      async handleUpload(files) {
        if (!files.length) {
          this.businessForm.icon_path = null
          return
        }

        this.fileUploadLoading = true
        store.dispatch('imageUpload', files).then(responses => {
          this.businessForm.icon_path = responses.key
          this.fileUploadLoading = false
        }).catch(() => {
          this.fileUploadLoading = false
        })
      },

      async onSubmit() {
        this.buttonIndicator = "on";
        await this.$refs.businessFormRef.validate(async valid => {
          await store.dispatch("updateBusinessDetails", this.businessForm)
            .then(res => {
              this.confirmBusinessUpdate();
                this.initialBusinessFormJSON = JSON.stringify(this.businessForm);
                NotificationService.swalCustom({
                title: "Success",
                icon: "success"
              });
            }).catch(err => {
              console.log(err, "----err");
            }).finally(() => {
              this.buttonIndicator = "off";
            });
        });
      },
      fillForm(businessData) {
        this.businessForm = {
          icon_path: businessData.icon_path,
          company_name: businessData.name,
          country: businessData.country,
          industries: businessData.industries,
          num_employees: businessData.num_employees,
          revenue: businessData.revenue
        };
      },
      confirmBusinessUpdate() {
        let businessData = { ...this.businessData };

        businessData.name = this.businessForm.company_name;
        businessData.country = this.businessForm.country;
        businessData.industries = this.businessForm.industries;
        businessData.num_employees = this.businessForm.num_employees;
        businessData.revenue = this.businessForm.revenue;
        businessData.icon_path = this.businessForm.icon_path;

        store.commit("SAVE_BUSINESS_INFO", businessData);
      },
      resetForm(){
        this.fillForm({ ...this.businessData });
      }
    }
  };
</script>
<style lang="scss">
@media (max-width: 768px) {
  .companyDetailsForm .el-form-item .el-form-item__label {
    width: max-content !important;
  }
}
</style>
